
import { defineComponent, ref, watch } from 'vue';
import { Folder, useDownloadStore } from '@/stores/download';
import { useOperatingSystemStore } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import BpDownloadFolderEditorVue from '@/components/download/BpDownloadFolderEditor.vue';

export default defineComponent({
  name: 'download-folder-editor',
  components: {
    BpDownloadFolderEditor: BpDownloadFolderEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useDownloadStore();
    const operatingSystemStore = useOperatingSystemStore();

    const download = ref<Folder>();

    function loadDownload() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        download.value = doc as Folder;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadDownload()
    }, { immediate: true });

    return {
      download,
      operatingSystemStore,
      store,
    }
  }
});
