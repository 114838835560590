import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    class: "bp-download-folder-editor",
    loading: _ctx.loading,
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("New folder")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { folder: _ctx.store.getName(_ctx.folder) }
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("Edit folder \"%{folder}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.download.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.folder && _ctx.operatingSystemStore.loaded)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-download-folder-editor__form",
            onSubmit: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.folder.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.folder.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.parents,
              label: _ctx.$gettext('Parent folder'),
              transform: _ctx.transformParent,
              onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent folder'), $event))),
              required: "",
              modelValue: _ctx.folder.parent_id,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.folder.parent_id) = $event))
            }, null, 8, ["data", "label", "transform", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Description'),
              onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('description', _ctx.$gettext('Description'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.folder.description,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.folder.description) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Hint'),
              onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('hint', _ctx.$gettext('Hint'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.folder.hint,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.folder.hint) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Order'),
              onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event))),
              type: "number",
              modelValue: _ctx.folder.order,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.folder.order) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Compatibilities')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_bp_input, {
                    flush: "",
                    type: "checkbox",
                    "model-value": _ctx.folder.compatibilities !== null,
                    text: _ctx.$gettext('Show compatibilities'),
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.folder.compatibilities = $event ? [] : null))
                  }, null, 8, ["model-value", "text"]),
                  _createVNode(_component_bp_select, {
                    data: _ctx.operatingSystemStore.getVersions(),
                    disabled: _ctx.folder.compatibilities === null,
                    "model-value": _ctx.folder.compatibilities || [],
                    transform: _ctx.operatingSystemStore.getName,
                    placeholder: _ctx.folder.compatibilities === null ? undefined : _ctx.store.getCompatibilities(_ctx.folder.parent_id || '').map(os => _ctx.removeHTML(_ctx.operatingSystemStore.getName(_ctx.operatingSystemStore.getById(os)))).join(', '),
                    onChangeValid: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setError('compatibilities', _ctx.$gettext('Compatibilities'), $event))),
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.folder.compatibilities = $event)),
                    clearable: "",
                    multiple: "",
                    "sort-order": "desc"
                  }, null, 8, ["data", "disabled", "model-value", "transform", "placeholder"])
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Feature latest file'),
              text: _ctx.$gettext('Feature the latest file in the menu and at the top of the download area.'),
              onChangeValid: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Feature latest file'), $event))),
              flush: "",
              type: "checkbox",
              modelValue: _ctx.folder.featured,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.folder.featured) = $event))
            }, null, 8, ["label", "text", "modelValue"]),
            (_ctx.folder.featured)
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 0,
                  label: _ctx.$gettext('Featured text'),
                  text: _ctx.$gettext('This is a replacement title for the latest file in the folder which will be displayed instead of the default generated one, e.g. "Latest file" in the main menu.'),
                  onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Featured'), $event))),
                  modelValue: _ctx.folder.featured_text,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.folder.featured_text) = $event))
                }, null, 8, ["label", "text", "modelValue"]))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}