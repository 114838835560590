
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { defaultFolder, Folder, useDownloadStore } from '@/stores/download';
import { getTranslated } from '@/translation';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useOperatingSystemStore } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';
import { removeHTML } from '@/utils/string';

export default defineComponent({
  name: 'bp-download-folder-editor',
  props: {
    modelValue: Object as PropType<Folder>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// FOLDER
    ///-------------------------------------------------------------------

    const initialFolder: OptionalKeys<Folder, '_id' | '_rev'> = {
      ...defaultFolder,
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const folder = ref<OptionalKeys<Folder, '_id' | '_rev'>>({ ...clone(initialFolder), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(folder.value, props.modelValue)) { return }
      folder.value = { ...clone(initialFolder), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Folder>();

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useDownloadStore();

    async function save() {
      let response;
      folder.value.doc_type = 'download_folder';
      if (folder.value._id) {
        response = await store.update(folder.value);
      } else {
        response = await store.create(folder.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: folder.value._id
            ? $gettext('Folder successfully updated')
            : $gettext('Folder successfully created'),
          content: folder.value._id
            ? $gettext('The folder was successfully updated.')
            : $gettext('The folder was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.download.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: folder.value._id
            ? $gettext('Failed to update folder')
            : $gettext('Failed to create folder'),
          content: folder.value._id
            ? $gettext('The folder could not be updated: %{error}', { error: response?.error })
            : $gettext('The folder could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = computed(() => {
      return store.getParents({ excludeIds: folder.value._id, includeRoot: true })
        .map(parent => {
          return ({ ...parent, icon: (parent._id === 'root' ? slashIcon(['far', 'folder']) : ['far', 'folder']) })
        }) as PopoverElement[];
    });

    const transformParent = (parent: Folder) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${getTranslated(parent.name)} <em style="opacity: 0.5;">(${path.map(parent => getTranslated(parent.name)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${getTranslated(parent.name)})</em>`
      }
      return getTranslated(parent.name) || '';
    };

    ///-------------------------------------------------------------------
    /// COMPATIBILITIES
    ///-------------------------------------------------------------------
    
    const operatingSystemStore = useOperatingSystemStore();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      errors,
      folder,
      isEdit,
      operatingSystemStore,
      parents,
      removeHTML,
      reset,
      save,
      saveDisabled,
      setError,
      slashIcon,
      store,
      transformParent,
    }
  }
})


